import React from 'react';
import './index.css';

class App extends React.Component {
    submit = () => {
        let http: XMLHttpRequest = new XMLHttpRequest();
        let url: string = '/api/auth';
        let code: HTMLInputElement | null = document.getElementById("code") as HTMLInputElement | null;
        let username: HTMLInputElement | null = document.getElementById("username") as HTMLInputElement | null;
        let password: HTMLInputElement | null = document.getElementById("password") as HTMLInputElement | null;
        let params: string = `code=${code?.value}&username=${username?.value}&password=${password?.value}`;
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.onreadystatechange = () => {
            if (http.readyState === 4 && http.status === 200) {
                switch (http.responseText) {
                    case "ok": window.location.reload(); break;
                    case "err": alert("Incorrect code"); break;
                    case "user_err": alert("User/Password error"); break;
                    case "no": alert("Format Error"); break;
                }
            }
        }
        http.send(params);
    }

    render(): React.ReactNode {
        return (
            <div>
                <div className='box'>
                    {/* <h2>Google Authenticator</h2> */}
                    <div className='form'>
                        <label>Username</label>
                        <input type='text' autoComplete="off" autoCapitalize="none" name="username" id='username' />
                        <label style={{ marginTop: '5px', display: 'block' }}>Password</label>
                        <input type='password' autoComplete="off" autoCapitalize="none" name="password" id='password' />
                        <label style={{ marginTop: '5px', display: 'block' }}>MFA code</label>
                        <input type='text' autoComplete="off" autoCapitalize="none" name="code" id='code' />
                        <div className='btn-box'>
                            <button onClick={this.submit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default App;